import React, { useRef, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { UserExists } from '../DbApi/Users.DbApi.js';
import { UserRoleSeConnecter } from '../DbApi/UserRole.DbApi.js';
import { useNavigate } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LoginIcon from '@mui/icons-material/Login';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Head } from 'react-static';
import caritasLogo from '../assets/images/caritas-logo.jpg';
// import pageBg from '../assets/images/pexels-photo-273238.jpeg';
import './LogInForm.scss';

const LogInForm = () => {
  const naviguer = useNavigate();
  const UserInfo = useRef({});
  const TheToken = localStorage.getItem('se');
  const [showPassword, setShowPassword] = useState(false);
  const [MainMessageError, setMainMessageError] = useState(null);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const [currentUser, SetcurrentUser] = useState(
    sessionStorage.getItem('currentUser')
  );

  useEffect(() => {
    console.log('currentUser ', JSON.parse(currentUser));
    if (JSON.parse(currentUser))
      naviguer('/bord', { state: JSON.parse(currentUser) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({ mode: 'onTouched' });
  const onSubmit = async (data) => {
    console.log('Le formulaire est validé. ', data);
    //  const re= await SeConnecter(data)
    const re = await UserRoleSeConnecter(data);
    if (!re.valid) {
      setMainMessageError('Mot de passe erroné');
    } else {
      setMainMessageError(null);
      sessionStorage.setItem('currentUser', JSON.stringify(re.user));
      SetcurrentUser(JSON.stringify(re.user));
      naviguer('/bord', { state: re.user });
    }
    console.log(re);
  };

  return (
    <div
      className="h-screen w-full flex flex-col justify-center items-center"
      // style={{ backgroundImage: `url(${pageBg})` }}
    >
      <Head>
        <title>
          Connexion | Plateforme de Suivi-Evaluation de Redevabilité et
          d'apprentissage| Caritas Bénin
        </title>
        <link
          href="https://fonts.googleapis.com/css?family=Cabin:400,700"
          rel="stylesheet"
        ></link>
      </Head>

      <div className="flex flex-col items-center justify-center">
        <div className="text-center text-4xl font-semibold text-red-900">
          Plateforme de Suivi-Evaluation de Redevabilité et d'apprentissage
        </div>
        <div className="flex flex-col items-center justify-center">
          <img
            src={caritasLogo}
            alt="Logo de Caritas Bénin "
            width={350}
            className="fluid"
          />
          <h3 className="text-center text-3xl mb-3 pb-3 font-bold text-black">
            Veuillez vous connecter
          </h3>
          <div className="w-[720] flex flex-col items-center justify-center">
            {!TheToken && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col items-center justify-center">
                  {MainMessageError && (
                    <h3 className="text-danger border-b text-center mb-3 pb-3 font-bold">
                      {MainMessageError}
                    </h3>
                  )}

                  <div className="w-full flex flex-col items-start justify-center">
                    <div className="mb-2">
                      <Controller
                        name="email"
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: 'Ce champ doit avoir une valeur',
                          validate: async (value) => {
                            let resultat = await UserExists(value);
                            return resultat === 1;
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { isTouched, isDirty, error },
                          formState,
                        }) => (
                          <TextField
                            fullWidth
                            label={
                              <span style={{ color: '#0D2801' }}>
                                {' '}
                                <MailOutlineIcon /> Email{' '}
                              </span>
                            }
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                          />
                        )}
                      />
                    </div>

                    <div>
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                      {errors.email && errors.email.type === 'validate' && (
                        <div className="text-danger">Ce Email n'existe pas</div>
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col items-start justify-center">
                    <div className="mb-3">
                      <Controller
                        name="password"
                        control={control}
                        defaultValue={''}
                        rules={{
                          required: 'Ce champ doit avoir une valeur',
                          validate: (value) => {
                            if (UserInfo.current.exist) {
                              return (
                                UserInfo.current.item[0].Userpwdl === value
                              );
                            }
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { isTouched, isDirty, error },
                          formState,
                        }) => (
                          <TextField
                            fullWidth
                            label={
                              <span style={{ color: '#0D2801' }}>
                                <VpnKeyIcon />
                                Mot de passe{' '}
                              </span>
                            }
                            onChange={onChange}
                            value={value}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClick} edge="end">
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      {errors.password && (
                        <small className="text-danger">
                          {errors.password.message}
                        </small>
                      )}
                      {errors.password &&
                        errors.password.type === 'validate' && (
                          <div className="text-danger">Mot de passe erroné</div>
                        )}
                    </div>
                  </div>

                  <div className="flex flex-row justify-center items-center">
                    <button
                      type="button"
                      className="inline-flex px-4 py-2 mx-2 my-3 items-center justify-center  bg-red-500  text-indigo-100 rounded-full hover:bg-red-900"
                      onClick={() => {
                        reset({ Email: '', PassWord: '' });
                        naviguer('/');
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 mx-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span>Annuler</span>
                    </button>

                    <button className="inline-flex px-4 py-2 mx-2 my-3 items-center text-indigo-100 bg-green-700 rounded-full hover:bg-green-900 ">
                      <LoginIcon />
                      <span>Connecter</span>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInForm;
