import EditIcon from '../../components/Icones/EditIcon';
import { useState, useEffect } from 'react';
import EditValeur from './EditValeur';
import { UpdateRens } from '../../DbApi/Renseigner.DbApi';
import { GetRens } from '../../DbApi/Renseigner.DbApi';

import ShowDialogDetailPanelMobile from './ShowDialogDetailPanelMobile';
// eslint-disable-next-line no-unused-vars
import { getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS } from '../../utils/general';
import IconBxsDetail from '../../components/Icones/IconBxsDetail';
import { toast } from 'react-toastify';

const ItemIndicateurMobileLayout = ({ idRenseigner, sources }) => {
  const [detail, setDetail] = useState(false);
  const [editBox, setEditBox] = useState(false);
  const [Element, SetElement] = useState({});
  const [valeurTemoin, SetvaleurTemoin] = useState(null);

  function EditeLine(data) {
    setEditBox(true);
  }
  useEffect(() => {
    console.log(
      'ItemIndicateurMobileLayout => useEffect  =>idRenseigner = ',
      idRenseigner
    );
    GetRens({ idRenseigner: idRenseigner }).then((detail2) => {
      SetElement(detail2);
      SetvaleurTemoin(detail2.Valeur);
    });

    /*   console.log(
      getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() + ' useEffect avec valuer=:  ',
      valeurTemoin
    ); */

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valeurTemoin, idRenseigner]);

  async function SaveAfterEdit(data) {
    var newElement = { ...Element, ...data };
    await UpdateRens(data);
    toast.success('Nouvelle valeur enregistrée avec success');
    SetElement(newElement);
  }

  return (
    <>
      <EditValeur
        data={Element}
        showModal={editBox}
        setShowModal={setEditBox}
        OnSaveData={SaveAfterEdit}
      />
      <ShowDialogDetailPanelMobile
        idRenseigner={idRenseigner}
        sources={sources}
        showModal={detail}
        setShowModal={setDetail}
      />

      <div className="bg-white rounded-md overflow-hidden shadow-xl shadow-red-500 ">
        {' '}
        {/* h-[27rem] shadow-[#50d71e] */}
        <span className="px-3 py-2 block">
          <span className="font-bold">Code :</span>{' '}
          <span className="font-light">{Element.Indicateurs_id}</span>
        </span>
        <span className="px-3 py-2 block">
          <span className="font-bold">Libellé:</span>{' '}
          <span className="font-light">{Element.LibIndicateur}</span>
        </span>
        <span className="px-3 pt-1 block">
          <span className="font-bold">Valeur:</span>{' '}
          <span className="font-light ">{Element.Valeur}</span>
        </span>
        <div className="flex flex-col justify-center items-center">
          <button
            className="inline-flex px-4 pt-1 mx-2 my-3 items-center text-indigo-100 bg-green-700 rounded-full hover:bg-green-900 "
            onClick={() => EditeLine(Element)}
          >
            <EditIcon className="mr-3" />
            <span className="ms-2">Modifier la Valeur</span>
          </button>
          <button
            className="inline-flex px-4 mx-2 my-3 items-center text-indigo-100 bg-blue-700 rounded-full hover:bg-blue-900 "
            onClick={() => setDetail(!detail)}
          >
            <IconBxsDetail className="mr-3" />
            <span>Affichier Détails</span>
          </button>
        </div>
      </div>
    </>
  );
};
export default ItemIndicateurMobileLayout;
