import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import TemplatePages from '../../Dashboard/TemplatePages';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputUser } from '../../../DbApi/Renseigner.DbApi';
import UserTableIndicateurs from './UserTableIndicateurs';

export default function UserInputs() {
  const naviguer = useNavigate();
  let tempo = sessionStorage.getItem('currentUser');
  const currentUser = JSON.parse(tempo);
  //console.log('AdminPta =>currentUser=', currentUser);
  if (!currentUser) {
    let message = 'Veuillez vous connecter';
    toast.error(message);
    naviguer('/login');
  }

  const [selectedRow] = useState(null);

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['UserDatasConnected', currentUser.id],
    queryFn: InputUser,
  });

  const options = {
    exportButton: false,
    pageSize: 65,
    pageSizeOptions: [65, 100, 150],
    actionsColumnIndex: -1,
    rowStyle: (rowData) => ({
      backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
    }),
    headerStyle: {
      position: 'sticky',
      top: '0',
      backgroundColor: 'brown',
      fontWeight: 'bold',
      color: 'white',
    },
    maxBodyHeight: '500px',
  };

  const columnsA = [
    { title: 'code', field: 'idIndicateur', editable: 'never' },
    { title: 'Indicateur', field: 'Libelle', editable: 'never' },
    { title: 'PTA 2020', field: 'PTA2020', editable: 'never' },
    { title: 'PTA 2021', field: 'PTA2021', editable: 'never' },
    { title: 'PTA 2022', field: 'PTA2022', editable: 'never' },
    { title: 'PTA 2023', field: 'PTA2023', editable: 'never' },
  ];

  const LeFilsA = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="flex justify-center items-center">
          {isSuccess && (
            <UserTableIndicateurs
              datas={data}
              columns={columnsA}
              options={options}
            />
          )}
        </div>
      </div>
    );
  };
  if (isLoading)
    return (
      <TemplatePages
        Fils={
          <div className="h-full flex justify-center items-center text-red-600 font-bold">
            Données en cours de chargement
          </div>
        }
      />
    );

  return <TemplatePages Fils={<LeFilsA />} />;
}
