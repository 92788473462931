// icon:bxs-detail | Boxicons https://boxicons.com/ | Atisa
import * as React from 'react';

function IconBxsDetail(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M20 3H4a2 2 0 00-2 2v14a2 2 0 002 2h16a2 2 0 002-2V5a2 2 0 00-2-2zm-9 14H5v-2h6v2zm8-4H5v-2h14v2zm0-4H5V7h14v2z" />
    </svg>
  );
}

export default IconBxsDetail;
