import { useEffect, useState } from 'react';
import { GetAllPta } from '../../DbApi/Pta.DbApi';
import { /*gen4user,*/ GetAllRens } from '../../DbApi/Renseigner.DbApi';
import { GetAllSources } from '../../DbApi/sources.DBApi';
//import { toast } from 'react-toastify';
import ItemIndicateurMobileLayout from './ItemIndicateurMobileLayout';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import Select from 'react-select';

export function IndicateursMobileLayout() {
  const queryClient = useQueryClient();
  const [RowsDatas, setRowsDatas] = useState([]);
  const [query, setQuery] = useState('');
  const [AnneePta, setAnneePta] = useState(
    queryClient.getQueryData(['LePtaChoisi'])
  );
  var param = queryClient.getQueryData(['LeParam']);
  const [sources, setSources] = useState([]);

  const handleChange = (selectedOption) => {
    //setSelectedOption(selectedOption);
    setAnneePta(selectedOption.value);
    queryClient.setQueryData(['LePtaChoisi'], () => {
      return selectedOption.value;
    });
    queryClient.setQueryData(['LeParam'], () => {
      let xx = queryClient.getQueryData(['LeParam']);
      xx.AnneePta = selectedOption.value;
      return xx;
    });
  };
  const LesYears = useQuery({
    queryKey: ['LesPTA'],
    queryFn: GetAllPta,
  });

  const defaultValuePta = (unevaleur) => {
    if (LesYears.isSuccess)
      return LesYears.data.find((item) => item.value === unevaleur);
    else return null;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
  };
  const searchFilter = (array) => {
    return array.filter((el) =>
      el.LibIndicateur.toLowerCase().includes(query.toLowerCase())
    );
  };

  useEffect(() => {
    async function fetchData() {
      let LesDatas = await GetAllRens(param);
      GetAllSources().then((datas) => setSources(datas));
      setRowsDatas(LesDatas);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AnneePta]);

  const filtered = searchFilter(RowsDatas);

  const SearchhandleChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <div className="flex flew-row justify-center text-red-900 font-bold ">
        Saisie des Indicateurs du PTA {AnneePta}
      </div>
      <div className="flex flex-row justify-between items-center ">
        {/* pour selectionner */}
        <div className="mt-3 flex flex-col justify-around items-center ">
          <span>Veuillez choisir une Année</span>
          <Select
            defaultValue={defaultValuePta(AnneePta)}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            onChange={handleChange}
            options={LesYears?.data}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: '#3599B8',
                font: '#3599B8',
                primary25: '#3599B8',
                primary: '#3599B8',
                neutral80: 'black',
                color: 'black',
              },
            })}
            styles={customStyles}
          />
        </div>
        {/* <div>
          <button
            className="bg-red-300"
            onClick={() => {
              toast.info('Vous avez cliqué sur générer');
              gen4user();
            }}
          >
            Tester Générer 4 User
          </button>
        </div> */}
        <div> Nombre d'éléments à affichier : {filtered.length}</div>
        {/* Pour rechercher */}
        <div className="flex flex-row justify-around items-start ">
          <input
            type="text"
            className="mt-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Rechercher"
            onChange={SearchhandleChange}
          />
        </div>{' '}
        {/*FIN Pour rechercher */}
      </div>
      <div className="grid grid-flow-row gap-4 text-neutral-600 grid-cols-1 sm:grid-cols-1  md:justify-items-center md:place-items-center md:items-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
        {filtered.map((indicateur, index) => (
          <ItemIndicateurMobileLayout
            key={index}
            idRenseigner={indicateur.idRenseigner}
            sources={sources}
          />
        ))}
      </div>
      <div></div>
    </>
  );
}
