import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './containers/Home';
import LogInForm from './containers/LogInForm';
import Logout from './containers/Logout';
//import AnnuelReportForm from './containers/AnnuelReport/AnnuelReportForm';
import ShowUploadRepportsForm from './containers/AnnuelReport/ShowUploadRepportsForm';
//import Evalpta from './containers/EvalPtas/Evalpta';
import Evalpta from './containers/Evalpta/Evalpta';
import SetSourcesVerifications from './containers/Evalpta/SetSourcesVerifications';
import ShowCreateUser from './containers/CreateUser/ShowCreateUser';
import { OnlineStatusProvider } from './helpers/useOnlineStatus';
//import Notifier from './store/Alertes/Notifier';
import ShowChangePassWord from './containers/ChangePassWord/ShowChangePassWord';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GetAllPta } from './DbApi/Pta.DbApi';
import { GetAllSources } from './DbApi/sources.DBApi';
import EntityInputs from './containers/Evalpta/EntityInputs/EntityInputs';
import { IsLocalHost } from './constants/mainUrls';
import UserInputs from './containers/Evalpta/UserInputs/UserInputs';

function MainApp() {
  const queryClient = new QueryClient();
  async function FetchGlobalDatas() {
    await Promise.all([
      queryClient.prefetchQuery({
        queryKey: ['LesPTA'],
        queryFn: GetAllPta,
      }),
      queryClient.prefetchQuery({
        queryKey: ['LesSources'],
        queryFn: GetAllSources,
      }),
    ]);
  }

  FetchGlobalDatas();
  queryClient.setQueryData(['LePtaChoisi'], () => {
    return '';
  });

  //Notifier();

  return (
    <QueryClientProvider client={queryClient}>
      <OnlineStatusProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LogInForm />} />
          <Route path="/chpssw" element={<ShowChangePassWord />} />
          <Route path="/bord" element={<Evalpta />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/rpword" element={<ShowUploadRepportsForm />} />
          <Route path="/evalps" element={<Evalpta />} />
          <Route path="/les4ans" element={<UserInputs />} />
          <Route path="/setsrc" element={<SetSourcesVerifications />} />
          <Route path="/crus" element={<ShowCreateUser />} />
          {/*  <Route path="/view" element={<AdminPta />} /> */}
          <Route path="/view" element={<EntityInputs />} />
        </Routes>
      </OnlineStatusProvider>
      {IsLocalHost && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

export default MainApp;
