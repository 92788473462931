/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './Evalpta.css';
import TemplatePages from '../Dashboard/TemplatePages';
import MainComponent from './MainComponent';
import { IndicateursMobileLayout } from './IndicateursMobileLayout';
import { useState } from 'react';
import IconToggleSwitchOutline from '../../components/Icones/IconToggleSwitchOutline';
import IconToggleSwitchOffOutline from '../../components/Icones/IconToggleSwitchOffOutline';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { GetAllRens, GetAllRens4User } from '../../DbApi/Renseigner.DbApi';
import IdleTimer from '../../helpers/IdleTimer';
import { useNavigate } from 'react-router-dom';
const Evalpta = () => {
  const naviguer = useNavigate();
  const queryClient = useQueryClient();
  let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const LePtaChoisi = queryClient.getQueryData(['LePtaChoisi']);
  const [isTimeout, setIsTimeout] = useState(false);
  if (isTimeout) {
    sessionStorage.removeItem('currentUser');
    naviguer('/');
  }

  useEffect(() => {
    /*   const timer = new IdleTimer({
      timeout: 900, //expire after N seconds
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
      },
    }); */
    /*    return () => {
      timer.cleanUp();
    }; */
  }, []);

  queryClient.setQueryData(['LeParam'], () => {
    return {
      Entiter: currentUser.EntiterId,
      AnneePta: LePtaChoisi,
      User_id: currentUser.id,
    };
  });
  queryClient.prefetchQuery({
    queryKey: ['RensDataUser', queryClient.getQueryData(['LeParam'])],
    queryFn: GetAllRens4User,
  });

  const [showTableForme, SetshowTableForme] = useState(false);

  const RenderComponent = () => {
    return showTableForme ? (
      <TemplatePages Fils={<MainComponent />} />
    ) : (
      <TemplatePages Fils={<IndicateursMobileLayout />} />
    );
  };
  return (
    <div>
      <div className="inline ">
        <button
          onClick={() => SetshowTableForme(!showTableForme)}
          className="bg-green-300 text+while font-bold flex flex-row"
        >
          {' '}
          <span className="inline ms-3 mx-2">Mode d'affichage:</span>
          {showTableForme ? (
            <span className="inline">
              <IconToggleSwitchOutline className="inline mr-1" /> Tableau
            </span>
          ) : (
            <span className="inline">
              {' '}
              <IconToggleSwitchOffOutline className="inline mr-1" /> Formulaire{' '}
            </span>
          )}
        </button>
      </div>
      <RenderComponent />
    </div>
  );
};

export default Evalpta;
