function getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() {
  function toString(number, padLength) {
    return number.toString().padStart(padLength, '0');
  }

  let date = new Date();

  let dateTimeNow =
    toString(date.getFullYear(), 4) +
    '_' +
    toString(date.getMonth() + 1, 2) +
    '_' +
    toString(date.getDate(), 2) +
    '__' +
    toString(date.getHours(), 2) +
    '_' +
    toString(date.getMinutes(), 2) +
    '_' +
    toString(date.getSeconds(), 2) +
    '_' +
    toString(date.getMilliseconds(), 3);
  return dateTimeNow;
}
module.exports = getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS;
