import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import TemplatePages from '../../Dashboard/TemplatePages';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InputEntities } from '../../../DbApi/Renseigner.DbApi';
import { createColumnHelper } from '@tanstack/react-table';
import EntityTableIndicateurs from './EntityTableIndicateurs';
import ShareTable from '../../../components/DataTable/ShareTable';
import { renseignerEntiter } from '../../../DbApi/Renseigner.DbApi';
import IconExportOutline from '../../../components/Icones/IconExportOutline';

export default function EntityInputs() {
  const naviguer = useNavigate();
  let tempo = sessionStorage.getItem('currentUser');
  const currentUser = JSON.parse(tempo);
  //console.log('AdminPta =>currentUser=', currentUser);
  if (!currentUser) {
    let message = 'Veuillez vous connecter';
    toast.error(message);
    naviguer('/login');
  } else {
    let index = currentUser.roles.findIndex(
      (item) => item.LibRole === 'Administrateur'
    );
    if (index === -1) {
      let message =
        "Vous n'avez pas les droits d'accès à cette fonctionnalité de la plateforme\n\rVous êtes automatiquement redirigés vers l'écran de saisie des indicateurs";
      toast.error(message);
      naviguer('/bord');
    }
  }

  const LesEntiters = [
    { value: '1', label: 'SCDIH Caritas Cotonou', typeEntiterId: '1' },
    { value: '2', label: ' Caritas Diocésaine de Dassa', typeEntiterId: '1' },
    { value: '3', label: ' Caritas Diocésaine de Djougou', typeEntiterId: '1' },
    { value: '4', label: ' Caritas Diocésaine de Kandi', typeEntiterId: '1' },
    { value: '5', label: ' Caritas Diocésaine de Lokossa', typeEntiterId: '1' },
    {
      value: '6',
      label: ' Caritas Diocésaine de Natitingou',
      typeEntiterId: '1',
    },
    { value: '7', label: " Caritas Diocésaine de N'Dali", typeEntiterId: '1' },
    { value: '8', label: ' Caritas Diocésaine de Parakou', typeEntiterId: '1' },
    { value: '9', label: ' Caritas Diocésaine de Abomey', typeEntiterId: '1' },
    {
      value: '10',
      label: ' Caritas Diocésaine de Porto-Novo',
      typeEntiterId: '1',
    },
    {
      value: '11',
      label: ' Direction Nationale de Caritas Bénin',
      typeEntiterId: '0',
    },
    { value: '12', label: 'Toutes', typeEntiterId: '0' },
  ];
  const [Entiter, setEntiter] = useState(null);
  const [TypeTab /*setTypeTab*/] = useState('A');
  const [selectedRow] = useState(null);

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['LesDonnees', Entiter],
    queryFn: InputEntities,
  });

  const handleChangeEntiter = async (selectedOption) => {
    setEntiter(selectedOption.value);
  };

  const setDefaultEntity = (valeur) => {
    return LesEntiters.find((item) => item.value === valeur);
  };
  const options = {
    exportButton: false,
    pageSize: 65,
    pageSizeOptions: [65, 100, 150],
    actionsColumnIndex: -1,
    rowStyle: (rowData) => ({
      backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
    }),
    headerStyle: {
      position: 'sticky',
      top: '0',
      backgroundColor: 'brown',
      fontWeight: 'bold',
      color: 'white',
    },
    maxBodyHeight: '500px',
  };

  const columnsA = [
    { title: 'code', field: 'idIndicateur', editable: 'never' },
    { title: 'Indicateur', field: 'Libelle', editable: 'never' },
    { title: 'PTA 2020', field: 'PTA2020', editable: 'never' },
    { title: 'PTA 2021', field: 'PTA2021', editable: 'never' },
    { title: 'PTA 2022', field: 'PTA2022', editable: 'never' },
    { title: 'PTA 2023', field: 'PTA2023', editable: 'never' },
  ];

  const columnHelper = createColumnHelper();
  const columnsB = [
    columnHelper.accessor('idIndicateur', { header: 'id Indicateur' }),
    columnHelper.accessor('Libelle', { header: 'Indicateur' }),
    columnHelper.accessor('PTA2020', { header: 'PTA 2020' }),
    columnHelper.accessor('PTA2021', { header: 'PTA 2021' }),
    columnHelper.accessor('PTA2022', { header: 'PTA 2022' }),
    columnHelper.accessor('PTA2023', { header: 'PTA 2023' }),
  ];
  //------Le composant Fils--------
  //------Le composant Fils--------------------------------------------

  //FIN Compsant Fils
  const LeFilsA = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="mt-3 flex flex-row justify-start items-center ">
          <span className="mx-1">Veuillez choisir une entité (diocèse)</span>
          <div className="w-auto">
            <Select
              menuPortalTarget={document.body}
              defaultValue={setDefaultEntity(Entiter)}
              menuPosition="fixed"
              onChange={handleChangeEntiter}
              options={LesEntiters}
            />
          </div>
        </div>
        <div className="flex justify-end items-center ">
          <button
            onClick={() => renseignerEntiter()}
            className="text-red-600 bg-green-400 font-bold mt-2"
          >
            <span className="flex flex-row justify-center items-center">
              <IconExportOutline className="mx-3" />
              <span className="mr-3"> Exporter vers Excel</span>
            </span>
          </button>
        </div>
        <div className="flex justify-center items-center">
          {isSuccess && (
            <EntityTableIndicateurs
              datas={data}
              columns={columnsA}
              options={options}
            />
          )}
        </div>
      </div>
    );
  };
  const LeFilsB = () => {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="mt-3 flex flex-row justify-start items-center ">
          <span className="mx-1">Veuillez choisir une entité (diocèse)</span>
          <div className="w-auto">
            <Select
              menuPortalTarget={document.body}
              defaultValue={setDefaultEntity(Entiter)}
              menuPosition="fixed"
              onChange={handleChangeEntiter}
              options={LesEntiters}
            />
          </div>
        </div>

        <div className="flex justify-center items-center">
          {isSuccess && <ShareTable datas={data} columns={columnsB} />}
        </div>
      </div>
    );
  };
  if (isLoading)
    return (
      <TemplatePages
        Fils={
          <div className="h-full flex justify-center items-center text-red-600 font-bold">
            Données en cours de chargement
          </div>
        }
      />
    );

  if (TypeTab === 'A') return <TemplatePages Fils={<LeFilsA />} />;
  else return <TemplatePages Fils={<LeFilsB />} />;
}
