import ServerRequest from '../helpers/ServerRequest';
import { ServerUrl } from '../constants/mainUrls';
import { GetAllPta } from './Pta.DbApi';
import { GetUserByEmail } from './Users.DbApi';
import * as excelJS from 'exceljs';
import { saveAs } from 'file-saver';
import getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS from '../helpers/getDateInFormat';
import { toast } from 'react-toastify';
export const GetAllRens4User = async ({ queryKey }) => {
  const param = queryKey[1];

  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/allrens/`,
    param
  );

  return data;
};
export const GetAllRens = async (param) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/allrens/`,
    param
  );
  return data;
};
export const GetAllRensV2 = async (param) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/allrensV2/`,
    param
  );
  return data;
};
export const GenererRenseigner = async (param) => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/generer/`);
  return data;
};
export const UpdateRens = async (param) => {
  console.log('UpdateRens ', param);
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/up/`, param);
  return data;
};
export const GetRens = async (param) => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/id/`, param);
  return data[0];
};
export const UserNoRenseigner = async () => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/nouser`);
  return data;
};
export const GetAllIndicateurs = async () => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/allindic`);
  return data;
};

const GenerateurRenseignerData = async (LesUsers) => {
  var LesDatasToSave = [];
  const AllPta = await GetAllPta();
  var AllIndic = await GetAllIndicateurs();

  Promise.all(
    // eslint-disable-next-line array-callback-return
    LesUsers.map((usr) => {
      // eslint-disable-next-line array-callback-return
      AllPta.map((pta) => {
        AllIndic.map(async (indic) => {
          let UnObj = {
            Indicateurs_id: indic.idIndicateur,
            User_id: usr.id,
            Entiter_id: usr.EntiterId,
            Valeur: 0,
            PTA_id: pta.value,
            PStrategique_id: pta.pStrategique_id,
          };

          //console.log('param ', param);
          LesDatasToSave.push(UnObj);

          // await ServerRequest.post(`${ServerUrl}/api/rens/for1user`, param);
        });
      });
    })
  ).then(() => {
    const arr = [];
    for (var z = 0; z < LesDatasToSave.length; z++)
      arr.push([
        LesDatasToSave[z].Indicateurs_id,
        LesDatasToSave[z].User_id,
        LesDatasToSave[z].Entiter_id,
        LesDatasToSave[z].Valeur,
        LesDatasToSave[z].PTA_id,
        LesDatasToSave[z].PStrategique_id,
      ]);

    console.log('FIN ', LesDatasToSave);
    console.log('Array of VALUE ', arr);
    let i,
      j,
      temparray,
      chunk = 200;
    for (i = 0, j = arr.length; i < j; i += chunk) {
      temparray = arr.slice(i, i + chunk);
      console.log('temparray  ', temparray);
      ServerRequest.post(`${ServerUrl}/api/rens/for1user`, temparray);
    }
  });
};

export const gen4user = async () => {
  const LesUsers = await UserNoRenseigner();
  console.log('LesUsers', LesUsers);
  GenerateurRenseignerData(LesUsers);
};

export const genForOnUser = async (email) => {
  const dataUsers = await GetUserByEmail({ email: email });
  GenerateurRenseignerData(dataUsers);
};

export const InputEntities = async ({ queryKey }) => {
  const param = queryKey[1];
  //console.log('InputEntities datas=', datas);
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/inputentities`,
    { EntiterId: param }
  );

  return data;
};
export const InputUser = async ({ queryKey }) => {
  const param = queryKey[1];
  const { data } = await ServerRequest.post(`${ServerUrl}/api/rens/InputUser`, {
    User_id: param,
  });

  return data;
};
export const renseignerEntiter = async () => {
  toast.info('Récupération des données depuis le server');
  // console.log('renseignerEntiter');
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/rens/renseignerentiter`
  );
  toast.info('Génération du fichier excel');
  const workbook = new excelJS.Workbook(); // Create a new workbook
  const worksheet = workbook.addWorksheet('Datas'); // New Worksheet
  worksheet.columns = [
    { header: 'Id Entité', key: 'id', width: 10 },
    { header: 'Nom Entité', key: 'NomEntiter', width: 60 },
    { header: 'Code Indicateur', key: 'idIndicateur', width: 20 },
    { header: 'Indicateur', key: 'Libelle', width: 120 },
    { header: '2020', key: 'PTA2020', width: 10 },
    { header: '2021', key: 'PTA2021', width: 10 },
    { header: '2022', key: 'PTA2022', width: 10 },
    { header: '2023', key: 'PTA2023', width: 10 },
  ];

  /*sheet.mergeCells("F1:F2");
  const row = worksheet.getRow(1);
row.eachCell((cell, rowNumber) => {
  worksheet.getColumn(rowNumber).alignment = {
    vertical: "middle",
    horizontal: "center"
  };
    sheet.getColumn(rowNumber).font = { size: 14, family: 2 };
});

https://builtin.com/software-engineering-perspectives/exceljs

  */
  worksheet.addRows(data);
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true };
  });
  //console.log(' data ', data);
  //console.log(workbook.xlsx);
  workbook.xlsx.writeBuffer().then(function (buffer) {
    // done
    // console.log(buffer);

    const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
    let fileName =
      'Exoprt du ' + getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() + '.xlsx';
    saveAs(blob, fileName);
    toast.success(
      'le fichier :\n' +
        fileName +
        ' \nsera bientôt téléchargé \nVeuillez consulter votre dossier de téléchargement',
      18000
    );
  });

  return data;
};
