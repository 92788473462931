import React from 'react';
import CancelIcon from './CancelIcon';
import DetailPanelMobile from './DetailPanelMobile';

export default function ShowDialogDetailPanelMobile({
  idRenseigner,
  sources,
  showModal,
  setShowModal,
}) {
  return (
    <>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none my-4">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-row justify-center items-center my-1 text-lg text-white border-b border-solid border-gray-300 rounded-t bg-red-800 ">
                  Sources de vérification et commentaire
                </div>
                <div className="flex flex-col justify-center items-center">
                  <DetailPanelMobile
                    idRenseigner={idRenseigner}
                    sources={sources}
                  />
                  <button
                    type="button"
                    className="inline-flex px-1 py-1 mx-4 my-1 items-center justify-center bg-red-400 text-indigo-100 rounded-full hover:bg-blue-900"
                    onClick={() => setShowModal(false)}
                  >
                    <CancelIcon />
                    <span className="mr-3">Fermer</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
}
