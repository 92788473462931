import React, { useState, useEffect } from 'react';
import './DetailPanel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS,
  isStringEmpty,
} from '../../utils/general';
import Select from 'react-select';
import {
  ApplyCommentaire,
  GetAllSources,
  getSourcesForIdRenseigner,
  SaveOnlySources,
} from '../../DbApi/sources.DBApi';
import { GetRens } from '../../DbApi/Renseigner.DbApi';
import { useQueryClient } from '@tanstack/react-query';

const DetailPanelMobile = ({ idRenseigner, sources }) => {
  const queryClient = useQueryClient();
  const [selectedOption, SetSelectedOption] = useState([]);
  const [firstOption, SetfirstOption] = useState([]);
  const [commentaire, setCommentaire] = useState('');
  const [commentaireOnFocus, setCommentaireOnFocus] = useState('');
  const [rowData, setRowData] = useState({});

  const GetSources = async () => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ['LesSources'],
        queryFn: GetAllSources,
      });
      console.log('GetSources by ReactQuery ', data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeCommentaire = (event) => {
    setCommentaire(event.target.value);
  };
  GetSources();
  const handleChange = async (TheselectedOption) => {
    SetSelectedOption(TheselectedOption);
    await SaveOnlySources(TheselectedOption, rowData.idRenseigner);
    SetfirstOption(TheselectedOption);
  };

  useEffect(() => {
    async function fetchData() {
      GetRens({ idRenseigner: idRenseigner }).then((detail) => {
        console.log('detail = ', detail);
        setRowData(detail);
        setCommentaire(detail.Commentaire);
      });

      console.log(
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
          ' UseEffect is called with this data= ',
        rowData
      );

      const resultat = await getSourcesForIdRenseigner({
        idRenseigner: idRenseigner,
      });
      var tableau2 = [];
      // eslint-disable-next-line array-callback-return
      resultat.map((item) => {
        let UneSource = sources.find((sc) => {
          return sc.value === item.Source_id;
        });
        tableau2.push(UneSource);
      });
      SetSelectedOption(tableau2);
      SetfirstOption(tableau2);
      //USStates.find(({ value }) => value === props.state)
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idRenseigner, sources]);

  const SaveCommentaire = async () => {
    await ApplyCommentaire(rowData.idRenseigner, commentaire);
    if (isStringEmpty(commentaire)) {
    } else {
    }
  };

  //idRenseigner=2522,2549
  const OnFocusHandeler = () => {
    setCommentaireOnFocus(commentaire);
  };
  const OnBludHandler = () => {
    // toast.info(`OldValue ; ${commentaireOnFocus} - NewValue : ${commentaire}`);
    if (commentaireOnFocus !== commentaire) SaveCommentaire();
  };
  return (
    <div className="flex flex-col justify-center items-center bg-slate-200">
      <div className="w-full mx-4">
        Résultat : {rowData.CodeResultat} -- {rowData.LibResultat}
      </div>
      <div className="mx-4 text-red-900 font-bold">
        Indicateur : {rowData.Indicateurs_id} -- {rowData.LibIndicateur}
      </div>

      <div className="mt-3">
        <strong>
          Veuillez choisir une ou plusieurs sources de vérification
        </strong>
      </div>
      <div className="col-8 ">
        <Select
          value={selectedOption}
          isMulti={true}
          name="Sources"
          options={sources}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleChange}
          // defaultValue={[colourOptions[2], colourOptions[3]]}
        />
      </div>
      <div className="flex flex-col justify-center items-center">
        <label className="mt-3">
          <strong>Commentaire/Observation (facultatif)</strong>
        </label>
        <textarea
          value={commentaire}
          onChange={handleChangeCommentaire}
          rows={6}
          onFocus={OnFocusHandeler}
          onBlur={OnBludHandler}
          className="resize rounded-md"
        />
      </div>
      {/* <div className="flex flex-row justify-center mt-3">
        <button
          onClick={SaveCommentaire}
          className="inline-flex px-4 py-2 mx-2 my-3 items-center text-indigo-100 bg-green-700 rounded-full hover:bg-green-900 "
        >
          <IconSave className="mr-3" />
          <span> Enregistrer Commentaire</span>{' '}
        </button>
      </div> */}
    </div>
  );
};

export default DetailPanelMobile;
