/* eslint-disable no-unused-vars */
import ServerRequest from '../helpers/ServerRequest';

import { ServerUrl } from '../constants/mainUrls';
import { store } from '../store/rootReducer';
import { toast } from 'react-toastify';
import { getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS } from '../utils/general';

export const SaveOnlySources = async (lesChoix, idRenseigner) => {
  let reset = false;
  let TheTab = [];

  // eslint-disable-next-line array-callback-return
  console.log('******** lesChoix', lesChoix);
  lesChoix.map((item) => {
    let UnObj = {
      Renseigner_id: idRenseigner,
      Source_id: item.value,
    };
    TheTab.push(UnObj);
  });
  console.log('******** Liste des sources sélectionnées', TheTab);

  if (lesChoix.length === 0) reset = true;
  let donnees = {
    reset: reset,
    TheTab: TheTab,
    idRenseigner: idRenseigner,
  };
  if (lesChoix.length === 0) {
    // suprimer les  sources
    resetSources(idRenseigner);
    toast.success(
      ' Toutes les sources de vérifications renseignées ont été supprimées avec success'
    );
  } else {
    SaveSourcesToDB(donnees);
  }
};

export const GetAllSources = async () => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/src/all/`);
  // console.log("/api/src/all/ ",data)
  return data;
};

export const SaveSourcesToDB = async (datas) => {
  console.log(
    getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
      ' Sources DBApi => SaveSourcesToDB reçues datas',
    datas
  );

  ServerRequest.post(`${ServerUrl}/api/src/apply/`, datas).then(
    (data) => {
      console.log(
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
          ' Sources DBApi => SaveSourcesToDB Resultat après exécution du ServerRequest.post  => ',
        data
      );

      toast.success('Sources enregistrées avec succes!');
      return data;
    },
    (error) => {
      console.error(
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
          ' Sources DBApi => ApplySources ERREUR après exécution du ServerRequest.post  => ',
        error
      );
      toast.error(
        getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
          " Erreor pendant l'enregistrement " +
          JSON.stringify(error)
      );

      throw error;
    }
  );
};
export const ApplyCommentaire = async (id, commentaire) => {
  let datas = {
    id: id,
    commentaire: commentaire,
  };
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/src/comm/`,
    datas
  );

  toast.success(' Commentaire enregistré avec succes');
};
export const getSourcesForIdRenseigner = async (datas) => {
  const { data } = await ServerRequest.post(
    `${ServerUrl}/api/src/gets/`,
    datas
  );
  console.log(
    getDateInFormat_yyyy_mm_dd_hh_mm_ss_SSS() +
      'DbApi, getSourcesForIdRenseigner  =>Datas for id =' +
      datas.idRenseigner +
      ' : =>',
    data
  );
  return data;
};
export const resetSources = async (id) => {
  const { data } = await ServerRequest.post(`${ServerUrl}/api/src/reset`, {
    idRenseigner: id,
  });
  console.log('resetSources retour ', data);
  return data;
};
