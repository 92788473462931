import axios from 'axios';
import { toast } from 'react-toastify';

const ServerRequest = axios.create({});
ServerRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const response = err?.response;
    const config = err?.config;
    const data = response?.data;
    const status = err?.status;
    //ERR_NETWORK
    var strMessage = '';
    if (err.code === 'ERR_NETWORK')
      strMessage = 'Il semblerait que la connexion internet ne fonctionne pas';
    const message = `${strMessage} \ncode erreur = ${err.code} \n=>status erreur= ${err.response?.status}`;

    if (err.response) {
      // If server responded with a status code for a request
      strMessage = `Data = ${err.response.data} \n Status = ${err.response.status}\n Headers =${err.response.headers}`;
      console.log('Data ', err.response.data);
      console.log('Status ', err.response.status);
      console.log('Headers ', err.response.headers);
      toast.error(strMessage);
    } else if (err.request) {
      // Client made a request but response is not received
      console.log('called', err.request);
      strMessage = `called = ${err.request}`;
      toast.error(strMessage);
    } else {
      // Other case
      console.log('Error', err.message);
      strMessage = `Error = ${err.message}`;
      toast.error(strMessage);
    }

    toast.error(message);
    return Promise.reject(err);
  }
);
export default ServerRequest;
