import React, { useEffect } from 'react';
import { Head } from 'react-static';
import caritasLogo from '../assets/images/caritas-logo.jpg';
import pageBg from '../assets/images/bg_image.jpg';
import LoginIcon from '@mui/icons-material/Login';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import './Home.scss';

const Home = () => {
  const naviguer = useNavigate();
  useEffect(() => {
    let currentUser = sessionStorage.getItem('currentUser');
    console.log('currentUser ', JSON.parse(currentUser));
    if (JSON.parse(currentUser)) naviguer('/bord');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="min-vh-100 d-flex flex-column  align-items-center"
      style={{ backgroundImage: `url(${pageBg})` }}
    >
      <Head>
        <title>
          Bienvenue | Plateforme de Suivi-Evaluation de Redevabilité et
          d'apprentissage | Caritas Bénin
        </title>
      </Head>
      <img
        className="fluid"
        src={caritasLogo}
        alt={'Logo Caritas Bénin'}
        width={350}
      />

      <div className="texte-white d-flex flex-column align-items-center  bgc">
        <h1 className="text-black text-2xl">
          Plateforme de Suivi-Evaluation, de Redevabilité et d'apprentissage
        </h1>
        <br />
        <span className="texte-center texte-white fs-5">
          Cette plateforme est conçue pour faciliter la transmission
          d'informations de suivi-évaluation et de rapportage entre les Diocèses
          du Bénin et le Siège National de Caritas Bénin. Si vous êtes autorisé
          à y accéder, veuillez cliquer ci-dessous pour vous connecter.
        </span>
        <br />
        <Button
          startIcon={<LoginIcon color="primary" />}
          onClick={() => naviguer('login')}
        >
          Connecter
        </Button>
      </div>
    </div>
  );
};

export default Home;
