import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import IconSave from '../../components/Icones/IconSave';
import CancelIcon from './CancelIcon';

export default function EditValeur({
  data,
  showModal,
  setShowModal,
  OnSaveData,
}) {
  const { Valeur, idRenseigner } = data;

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const submitHandler = async (datas) => {
    datas.idRenseigner = idRenseigner;
    OnSaveData(datas);
    setShowModal(false);
  };

  useEffect(() => {
    reset({ Valeur });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none my-4">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-row justify-center items-center p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-3xl font-semibold">Modifier Valeur</h3>
                </div>
                <div className="relative p-6 flex-auto">
                  <button
                    type="button"
                    className="inline-flex px-4 py-2 mx-2 my-3 items-center justify-center bg-blue-700 text-indigo-100 rounded-full hover:bg-blue-900"
                    onClick={() => setShowModal(false)}
                  >
                    <CancelIcon />
                    <span>Fermer</span>
                  </button>
                  <form
                    className="bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 w-full"
                    onSubmit={handleSubmit(submitHandler)}
                  >
                    <div className="mb-4">
                      <input
                        type="hidden"
                        className="w-full"
                        id="idRenseigner"
                        autoFocus
                        {...register('idRenseigner')}
                      />
                    </div>
                    {/*     <div className="mb-4">
                      <InputNumber
                        id="testNumeric"
                        autoFocus
                        title={'Numéric Input'}
                        ref={register({
                          valueAsNumber: true,
                        })}
                      />
                    </div> */}

                    <div className="mb-4">
                      <label htmlFor="Valeur">Valeur</label>
                      <input
                        type="number"
                        className="w-full"
                        id="name"
                        autoFocus
                        {...register('Valeur', {
                          required: 'Veuillez saisir une valeur numérique',
                          valueAsNumber: true,
                          validate: async (value) => {
                            //let resultat = await getUserByName(value);
                            let resultat = null;
                            return resultat === null;
                          },
                        })}
                      />
                      {errors.Valeur && (
                        <div className="text-red-500">
                          {errors.Valeur.message}
                        </div>
                      )}
                      {errors.valeur && errors.Valeur.type === 'validate' && (
                        <div className="text-red-500">
                          Ce Nom est déjà utilisé
                        </div>
                      )}
                    </div>
                    <div className="flex flex-row justify-center items-center border-2 border-red-900 my-5">
                      <button
                        type="button"
                        className="inline-flex px-4 py-2 mx-2 my-3 items-center justify-center  bg-red-500  text-indigo-100 rounded-full hover:bg-red-900"
                        onClick={() => reset({ Valeur })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 mx-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <span>Annuler</span>
                      </button>

                      <button className="inline-flex px-4 py-2 mx-2 my-3 items-center text-indigo-100 bg-green-700 rounded-full hover:bg-green-900 ">
                        <IconSave />
                        <span>Enregistrer</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        false
      )}
    </>
  );
}
